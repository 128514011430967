import Splide from '@splidejs/splide';
import 'scroll-timeline-polyfill'

import Parvus from 'parvus';
import de from 'parvus/src/l10n/de'
const parvus = new Parvus({
  l10n: de,
  lightboxIndicatorIcon: ''
})

import {klaroConfig} from './klaro-config';
import * as klaro from 'klaro';

// Klaro
klaro.render(klaroConfig);
document.querySelectorAll('[data-klaro-service]').forEach(el => {
  el.addEventListener('click', e => {
    klaro.getManager(klaroConfig).updateConsent(el.dataset.klaroService, true);
    klaro.getManager(klaroConfig).saveAndApplyConsents();
  });
});
document.querySelectorAll('[data-klaro-service]').forEach(el => {
  el.addEventListener('click', e => {
    klaro.getManager(klaroConfig).updateConsent(el.dataset.klaroService, true);
    klaro.getManager(klaroConfig).saveAndApplyConsents();
  });
});
document.querySelectorAll('[data-klaro-settings]').forEach(el => {
  el.addEventListener('click', e => {
    klaro.show(klaroConfig);
    e.preventDefault();
  });
});

// Splide
const splideSettings = {
  arrowPath: 'M10.513,37.227C9.984,37.804 9.984,38.699 10.513,39.276C10.759,39.546 11.108,39.701 11.474,39.701C11.84,39.701 12.189,39.546 12.435,39.276L27.963,22.725C28.492,22.148 28.492,21.253 27.963,20.676L12.435,4.125C12.189,3.855 11.84,3.7 11.474,3.7C11.108,3.7 10.759,3.855 10.513,4.125C9.984,4.702 9.984,5.597 10.513,6.174L24.675,21.7L10.513,37.227Z',
  i18n:  {
    de: {
      prev: 'Vorheriges Slide',
      next: 'Nächstes Slide',
      first: 'Gehe zum ersten Slide',
      last: 'Gehe zum letzten Slide',
      slideX: 'Gehe zu Slide %s',
      pageX: 'Gehe zu Seite %s',
      play: 'Starte Autoplay',
      pause: 'Pausiere Autoplay',
      carousel: 'Karusell',
      select: 'Wählen Sie ein Slide zum Anzeigen',
      slide: 'Slide',
      slideLabel: '%s von %s'
    }
  }
}
const documentLanguage = document.documentElement.getAttribute('lang') || 'de';

// Navbar Toggle
document.querySelectorAll('[aria-controls]').forEach(btn => {
  let controled = document.getElementById(btn.getAttribute('aria-controls'))
  if(!controled) return;

  btn.addEventListener('click', e => {
    let expanded = controled.getAttribute('aria-expanded')
    if(expanded == null || expanded == 'false') {
      expanded = 'true'
    } else {
      expanded = 'false'
    }
    btn.setAttribute('aria-expanded', expanded)
    controled.setAttribute('aria-expanded', expanded)
  })
})

// Not in Backend
if(!document.body.classList.contains('neos-backend')) {

  // Splide, Cards
  document.querySelectorAll('.cards .splide').forEach(cardSplide => {
    let splide = new Splide(cardSplide, {
      perPage: 3,
      gap: 'var(--gutter)',
      arrows: false,
      pagination: true,
      i18n: splideSettings.i18n[documentLanguage],
      breakpoints: {
        768: {
          perPage: 2
        },
        360: {
          perPage: 1
        }
        
      }
    }).mount()
  })


  // Scroll Timeline, Circle
  const revealCircleElements = document.querySelectorAll('.reveal-circle');
  revealCircleElements.forEach((revealElement) => {
    revealElement.animate(
      {
        clipPath: ['circle(10rem)', 'circle(65vmax)'],
        minHeight: ['0','100vh']
      },
      {
        timeline: new ViewTimeline({
          subject: revealElement,
          axis: 'vertical',
        }),
        fill: 'both',
        delay: { phase: 'enter', percent: CSS.percent(10) },
        endDelay: { phase: 'contain', percent: CSS.percent(50) }
      }
    );
  });

}



// Splide, Projekt-Liste, 2
document.querySelectorAll('.splide.project-list--two').forEach(projectSplide => {
  let splide = new Splide(projectSplide, {
    mediaQuery: 'min',
    perPage: 1,
    gap: 'var(--gutter)',
    arrows: true,
    arrowPath: splideSettings.arrowPath,
    pagination: true,
    i18n: splideSettings.i18n[documentLanguage],
    breakpoints: {
      768: {
        destroy: true
        
      }    
    }
  }).mount()
})

// Splide, Projekt-Liste, 3+
document.querySelectorAll('.splide.project-list--more').forEach(projectSplide => {
  let splide = new Splide(projectSplide, {
    perPage: 3,
    gap: 'var(--gutter)',
    arrows: true,
    arrowPath: splideSettings.arrowPath,
    pagination: false,
    i18n: splideSettings.i18n[documentLanguage],
    breakpoints: {
      992: {
        perPage: 2,
        arrows: false,
        pagination: true
      },
      360: {
        perPage: 1
      }      
    }
  }).mount()
})

// Scroll Timeline, Scroll up
const revealScrollElements = document.querySelectorAll('.reveal-scroll');
const revealScrollTimeline = new ScrollTimeline({
  source: document.scrollingElement,
  scrollOffsets: [new CSSUnitValue(0, 'vh'), new CSSUnitValue(50, 'vh')]
});
revealScrollElements.forEach((revealElement) => {
  revealElement.animate(
    {
      transform: ['translate(0,0)','translate(0,-7.5rem)'],
    },
    {
      duration: 1,
      easing: 'ease',
      timeline: revealScrollTimeline
    }
  );
});